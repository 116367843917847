import { User } from '../../auth/models/auth.model';
import { Cache, CacheFactory } from 'cachefactory';
import { Injectable } from '@angular/core';
import { ParsedFullSiteInfo } from '../../site/models/site.model';
import { TrekModel } from '../../trek/models/trek.model';
import { SiteCard, TrekCard } from '../models/models';
import { Campaign, Certificates } from '../../campaign/campaign.model';

@Injectable({ providedIn: 'root' })
export class CacheService {
    private cacheFactory = new CacheFactory();

    private searchResults: Cache;
    private homePage: Cache;
    private explorePage: Cache;
    private scrollPosition: Cache;
    private trekPage: Cache;
    private userPage: Cache;
    private sitePage: Cache;
    private campaign: Cache;

    constructor() {
        this.searchResults = this.cacheFactory.createCache('search-results');
        this.homePage = this.cacheFactory.createCache('home-page');
        this.explorePage = this.cacheFactory.createCache('explore-page');

        this.scrollPosition = this.cacheFactory.createCache('scroll-position');
        this.trekPage = this.cacheFactory.createCache('trek-page');
        this.userPage = this.cacheFactory.createCache('user-page');
        this.sitePage = this.cacheFactory.createCache('site-page');
        this.campaign = this.cacheFactory.createCache('campaign');

        // Save Scroll position on route change
        // this.$rootScope.$on(
        //     '$locationChangeSuccess',
        //     (event: ng.IAngularEvent, newUrl: string, oldUrl: string) => {
        //         this.saveScrollPosition(oldUrl);
        //     }
        // );
    }

    clearCache() {
        this.searchResults.removeAll();
        this.homePage.removeAll();
        this.explorePage.removeAll();
        this.scrollPosition.removeAll();
        this.trekPage.removeAll();
        this.userPage.removeAll();
        this.sitePage.removeAll();
        this.campaign.removeAll();
    }

    // =================================
    // ===== Scroll Position Cache =====
    // =================================

    // getLastScrollPosition() {
    //     const position = this.scrollPosition.get(this.$location.absUrl()) ||
    // 0; (this.$document[0].body as any).scrollTop = position; return
    // position; }  saveScrollPosition(path) { return
    // this.scrollPosition.put(path, this.$document[0].body.scrollTop); }

    // =============================
    // ===== Search Page Cache =====
    // =============================

    getSearchResults() {
        return this.searchResults.get('results');
    }

    getSearchQuery() {
        return this.searchResults.get('query');
    }

    getSearchAmount() {
        return this.searchResults.get('amount');
    }

    getFilterType() {
        return this.searchResults.get('filterType');
    }

    getSearchFilter() {
        return this.searchResults.get('filter');
    }

    saveSearchResults(
        query: any,
        results: any,
        amount: any,
        filterType: any,
        filter: any
    ) {
        this.searchResults.put('results', results);
        this.searchResults.put('query', query);
        this.searchResults.put('amount', amount);
        this.searchResults.put('filterType', filterType);
        this.searchResults.put('filter', filter);
    }

    // ===========================
    // ===== Home Page Cache =====
    // ===========================

    getHomeTreks(): TrekCard[] {
        return this.homePage.get('treks');
    }

    saveHomeTreks(treks: TrekCard[]) {
        return this.homePage.put('treks', treks);
    }

    getHomeSites(): SiteCard[] {
        return this.homePage.get('sites');
    }

    saveHomeSites(sites: SiteCard[]) {
        return this.homePage.put('sites', sites);
    }

    // ===========================
    // === Explore Page Cache ====
    // ===========================

    getExploreTreks(): TrekCard[][] {
        return this.explorePage.get('treks');
    }

    saveExploreTreks(treks: TrekCard[][]) {
        return this.explorePage.put('treks', treks);
    }

    getExploreSites(): SiteCard[][] {
        return this.explorePage.get('sites');
    }

    saveExploreSites(sites: SiteCard[][]) {
        return this.explorePage.put('sites', sites);
    }

    // ============================
    // ===== Trek Pages Cache =====
    // ============================

    getTrekInfo(trekId: string): TrekModel {
        return this.trekPage.get('trek-' + trekId);
    }

    saveTrekInfo(trekId: string, trekInfo: TrekModel) {
        return this.trekPage.put('trek-' + trekId, trekInfo);
    }

    getTrekCoordinates(trekId: string) {
        return this.trekPage.get('coordinates-' + trekId);
    }

    saveTrekCoordinates(trekId: string, coordinates: any) {
        return this.trekPage.put('coordinates-' + trekId, coordinates);
    }

    // ===================================
    // ===== Profile\User Page Cache =====
    // ===================================

    getUserInfo(userId: string): User {
        return this.userPage.get('userInfo-' + userId);
    }

    saveUserInfo(userId: string, info: User) {
        return this.userPage.put('userInfo-' + userId, info);
    }

    deleteUserInfo(userId: string) {
        return this.userPage.remove('userInfo-' + userId);
    }

    // ===========================
    // ===== Site Page Cache =====
    // ===========================

    getSiteInfo(siteId: string): ParsedFullSiteInfo {
        return this.sitePage.get('site-' + siteId);
    }

    saveSiteInfo(siteId: string, siteInfo: ParsedFullSiteInfo) {
        return this.sitePage.put('site-' + siteId, siteInfo);
    }

    // ===========================
    // ===== Campaign Cache =====
    // ===========================

    getCampaignInfo(campaignId: string): Campaign {
        return this.campaign.get('campaign-' + campaignId);
    }

    saveCampaignInfo(campaignId: string, campaignInfo: Campaign) {
        return this.campaign.put('campaign-' + campaignId, campaignInfo);
    }

    getCertificatesInfo(campaignId: string): Certificates {
        return this.campaign.get('certificates-' + campaignId);
    }

    saveCertificatesInfo(campaignId: string, certificatesInfo: Certificates) {
        return this.campaign.put(
            'certificates-' + campaignId,
            certificatesInfo
        );
    }
}
